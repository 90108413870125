<template>
    <div class="main">
      <b-overlay
        :show="loader"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <router-view></router-view>
      </b-overlay>
    </div>
</template>

<script>
import Header from "./Header"
import Footer from "./Footer"

export default {
  name: 'App',
  components: { Header, Footer },
  computed: {
    loader () {
      return this.$store.getters['getLoader']
    }
  }
}
</script>

<style scoped>

</style>
