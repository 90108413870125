<template>
    <div class="footer"></div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>

</style>
