import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth/index'
import filters from './filters/index'
import axios from '@/libraries/axios.js'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  modules: {
    auth,
    filters
  },
  state: {
    user: localStorage.getItem('user'),
    userToken: localStorage.getItem('userToken'),
    initData: null,
    loader: false
  },
  mutations: {
    setUserToken (state, value) {
      state.userToken = value
      localStorage.setItem('userToken', value)
    },
    setUser (state, value) {
      state.user = value
    },
    setInitData (state, data) {
      state.initData = data
    },
    setLoader (state, value) {
      state.loader = value
    }
  },
  actions: {
    getInitData () {
      return new Promise((resolve, reject) => {
        axios
          .get('get_initialization_data')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    importData (store, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('import_data', data, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
  getters: {
    getUserToken (state) {
      return state.userToken
    },
    getUser (state) {
      return JSON.parse(state.user)
    },
    getInitData (state) {
      return state.initData
    },
    getLoader (state) {
      return state.loader
    }
  }
})
