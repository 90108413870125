export default {
  methods: {
    /**
     * This method unpacking files from the store, and convert from FormData format to simple array of files
     *
     * @param files. Array of files in FormData format.
     * @return Array of unpacking files
     */
    unpackingFiles () {
      const result = []
      for (const pair of files.entries()) {
        if (pair[1] instanceof File) {
          result.push(pair[1])
        } else {
          result.push(JSON.parse(pair[1]))
        }
      }
      return result
    },
    /**
     *
     * @param files. Array of files
     * @return Array of packing files
     */
    packagingFiles (files) {
      const result = new FormData()
      Array.from(files).forEach((file, index) => {
        if (file instanceof File) {
          result.append('files[' + index + ']', file)
        } else {
          result.append('files[' + index + ']', JSON.stringify(file))
        }
      })
      return result
    },
    parseResponseErrors (error) {
      let text = ''
      for (const errorProperty in error.response.data.errors) {
        for (const errorType in error.response.data.errors[errorProperty]) {
          text =
            text + `${error.response.data.errors[errorProperty][errorType]}\n`
        }
      }
      return text
    },
    errorNotification (text, modalTimeoutSeconds = 1) {
      let modalSetTimeout = null
      return new Promise((resolve) => {
        const messageVNode = this.messageNode(
          'fa fa-times',
          'text-danger',
          text
        )
        this.$bvModal
          .msgBoxOk(messageVNode, {
            hideHeaderClose: false,
            hideFooter: true,
            contentClass: 'success-modal-content',
            centered: true,
            dialogClass: 'success-modal-dialog',
            bodyClass: 'success-modal-body',
            footerClass: 'success-agenda-footer',
            size: 'md',
            id: 'error-message'
          })
          .then(result => resolve(result))
          .finally(() => {
            clearTimeout(modalSetTimeout)
          })

        if (null !== modalTimeoutSeconds) {
          modalSetTimeout = setTimeout(() => {
            this.$bvModal.hide('error-message')
          }, modalTimeoutSeconds * 1000)
        }
      })
    },
    successNotification (text) {
      let modalSetTimeout = null
      const modalTimeoutSeconds = 1
      return new Promise((resolve) => {
        const messageVNode = this.messageNode(
          'fa fa-check',
          'text-success',
          text
        )
        this.$bvModal
          .msgBoxOk([messageVNode], {
            hideHeaderClose: false,
            hideFooter: true,
            contentClass: 'success-modal-content',
            centered: true,
            dialogClass: 'success-modal-dialog',
            bodyClass: 'success-modal-body',
            footerClass: 'success-agenda-footer',
            size: 'md',
            id: 'modalExample'
          })
          .then(result => resolve(result))
          .finally(() => {
            clearTimeout(modalSetTimeout)
          })
        modalSetTimeout = setTimeout(() => {
          this.$bvModal.hide('modalExample')
        }, modalTimeoutSeconds * 1000)
      })
    },
    errorModal (title, text, okButton) {
      return new Promise((resolve) => {
        this.$swal({
          icon: 'error',
          title: title,
          text: text,
          swalFooter: '',
          buttons: {
            confirm: {
              text: okButton,
              className: 'btn-sub-primary rounded-pill align-center'
            }
          }
        }).then((confirm) => {
          if (confirm) {
            resolve(true)
          }
        })
      })
    },
    messageNode (icon, iconStyle, text) {
      const h = this.$createElement
      return h(
        'div',
        { class: ['d-flex align-items-center notification-text'] },
        [
          h('i', { class: [icon + ' ' + iconStyle] }),
          h('span', { class: ['ml-3'] }, [text])
        ]
      )
    }
  }
}
