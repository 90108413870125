<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light header">
        <div class="container-fluid">
            <h1 class="navbar-brand">Import Tool</h1>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: 'Home' }">Scores</router-link>
                    </li>
<!--                    <li class="nav-item">-->
<!--                        <router-link class="nav-link" :to="{ name: 'Dashboard' }">Dashboard</router-link>-->
<!--                    </li>-->
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'Header'
}
</script>

<style scoped>

</style>
