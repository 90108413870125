import axios from 'axios'
import { APIURL } from '../../.env.js'

const TOKEN = localStorage.getItem('userToken')

let axiosInst = axios.create({
  baseURL: APIURL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
});

// axiosInst = setUserToken(axiosInst, TOKEN)

// axiosInst.interceptors.response.use((response) => {
//   return response
// }, (error) => {
//   if (undefined !== error.response) {
//       if ('User token authentication failure.' === error.response.data.message || 'Invalid user token provided.' === error.response.data.message) {
//         window.location.replace('/auth')
//       }
//
//       if ('This action is unauthorized.' === error.response.data.message) {
//         return window.location.replace('/')
//       }
//   }
//
//   return Promise.reject(error)
// })

export default axiosInst


export function setUserToken (axiosInst, token) {
   axiosInst.interceptors.request.use((request) => {
    request.headers.common.Authorization = `Bearer ${token}`;
    request.headers.common['usertoken'] = token;
    return request
  })
  return axiosInst
}
