import axios from '@/libraries/axios.js'

export default {
  namespaced: true,
  state: {
    districtList: [],
    selectedDistrict: null,
    selectedSourceType: null,
    selectedYear: null,
    selectedMeasurementType: null,
    processFile: null
  },
  mutations: {
    setDistrictList (state, value) {
      state.districtList = value
    },
    selectDistrict (state, value) {
      state.selectedDistrict = value
    },
    selectSourceType (state, value) {
      state.selectedSourceType = value
    },
    selectYear (state, value) {
      state.selectedYear = value
    },
    selectMeasurementType (state, value) {
      state.selectedMeasurementType = value
    },
    setProcessFile (state, value) {
      state.processFile = value
    }
  },
  actions: {
    getDistricts () {
      return new Promise((resolve, reject) => {
        axios.get('get_districts')
          .then((response) => {
            resolve(response.data.data)
          })
          .catch(error => reject(error))
      })
    },
    getMeasurementTypes (store, selectedDistrict) {
      return new Promise((resolve, reject) => {
        axios.get(`get_measurement_type_list?districtId=${selectedDistrict.id}`)
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },
    getDataSourceTypeList (store, selectedDistrict) {
      return new Promise((resolve, reject) => {
        axios.get(`get_data_sourse_type_list?districtId=${selectedDistrict.id}`)
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    }
  },
  getters: {
    getDistrictList (state) {
      return state.districtList
    },
    getSelectedDistrict (state) {
      return state.selectedDistrict
    },
    getSelectedSourceType (state) {
      return state.selectedSourceType
    },
    getSelectedYear (state) {
      return state.selectedYear
    },
    getSelectedMeasurementType (state) {
      return state.selectedMeasurementType
    },
    getProcessFile (state) {
      return state.processFile
    }
  }
}
