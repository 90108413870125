import axios from '@/libraries/axios.js'

export default {
    namespaced: true,
    state: {
        userToken: '',
        user: null,
        userDistrict: null,
        isValidSessionToken: null !== localStorage.getItem('isValidSessionToken') ? localStorage.getItem('isValidSessionToken') : false
    },
    mutations: {
        setSessionToken (state, payload) {
            state.isValidSessionToken = payload
        },
        set_user_district (state, payload) {
            state.userDistrict = payload
        },
        SET_USER_TOKEN (state, payload) {
            state.userToken = payload
        },
        SET_USER_DATA (state, payload) {
            state.user = payload
        },
        ClEAR_USER_DATA (state) {
            state.userToken = ''
            state.user = null
        }
    },
    actions: {
        getDistrict ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('get_district?district_id=' + payload.districtId)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        getSignInToken () {
            return new Promise((resolve, reject) => {
                axios
                    .post('sign_in_token', {
                        redirectUrl: window.location.origin + '/get-user-token'
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        getUserByToken ({ commit, state }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`get_user?userToken=${payload.token}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        getInitializationData ({ commit }, userId) {
            return new Promise((resolve, reject) => {
                axios
                    .post('get_init_data', {
                        userID: userId
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error.response))
            })
        },
        getSessionTokenStatus ({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`tool_session_validate?asid=${payload.asid}&auid=${payload.auid}`)
                    .then((response) => {
                        commit('setSessionToken', response.data.data.isValid)
                        localStorage.setItem('isValidSessionToken', response.data.data.isValid)
                        resolve(response.data.data)
                    })
                    .catch(error => reject(error.response))
            })
        }
    },
    getters: {
        get_user_district (state) {
            return state.userDistrict
        },
        GET_USER (state) {
            return state.user
        },
        getSessionTokenStatus (state) {
            return state.isValidSessionToken
        }
    }
}
