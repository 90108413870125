//-------------- IMPORT ALL LIBRARY MODULES ------------------//
import Vue from 'vue'
import VueRouter from 'vue-router'
import { ToastPlugin, ModalPlugin, BootstrapVue } from 'bootstrap-vue'
import Multiselect from 'vue-multiselect';
//--------------- END ALL LIBRARY MODULES ------------------//

// Import Router module
import router from './router'

// Import Store module
import store from './store'

// Main App Component
import App from './layouts/App.vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

// Import Global Mixin
import GlobalMixin from './global-mixin'

// Make BootstrapVue available throughout your project
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)

Vue.component('multiselect', Multiselect)

// import assets styles
require('@/assets/style.scss')

// Import font awesome styles
import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'

Vue.mixin(GlobalMixin)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
